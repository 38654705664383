import { Box, Flex, HStack, Text, Grid, Skeleton } from '@chakra-ui/react';
import React from 'react';

import type { Transaction } from 'types/api/transaction';

import config from 'configs/app';
import getValueWithUnit from 'lib/getValueWithUnit';
import { currencyUnits } from 'lib/units';
import { txTypesColors } from 'theme/foundations/colors';
import AddressFromTo from 'ui/shared/address/AddressFromTo';
import TxEntity from 'ui/shared/entities/tx/TxEntity';
import IconSvg from 'ui/shared/IconSvg';
import TxStatus from 'ui/shared/statusTag/TxStatus';
import TimeAgoWithTooltip from 'ui/shared/TimeAgoWithTooltip';
import TxFee from 'ui/shared/tx/TxFee';
import TxWatchListTags from 'ui/shared/tx/TxWatchListTags';
import getTxMethodTypeConfig from 'ui/txs/getTxMethodTypeConfig';
import TxMethodType from 'ui/txs/TxMethodType';
import TxType from 'ui/txs/TxType';

type Props = {
  tx: Transaction;
  isLoading?: boolean;
};

const LatestTxsItem = ({ tx, isLoading }: Props) => {
  const txMethodTypeConfig = getTxMethodTypeConfig(tx);

  // const transfer = extractTransferByType(tx);
  // console.log(transfer);

  const renderLabel = () => {
    if (txMethodTypeConfig) {
      return <TxMethodType methodTypeConfig={txMethodTypeConfig} />;
    }

    return <TxType types={tx.tx_types} isLoading={isLoading} />;
  };

  const renderIcon = () => {
    if (txMethodTypeConfig) {
      return (
        <IconSvg
          name={txMethodTypeConfig.icon}
          w={{ base: '40px' }}
          h={{ base: '40px' }}
          mt={2}
          mr={1}
        />
      );
    }

    return null;
  };

  const renderTypeBorder = () => {
    if (txMethodTypeConfig) {
      return {
        content: '""',
        position: 'absolute',
        left: '-1px',
        top: 0,
        width: '4px',
        height: '100%',
        background: `linear-gradient(to top, #0b0b0b, ${
          txTypesColors[txMethodTypeConfig?.colorScheme]
        })`,
        zIndex: 1,
      };
    }
  };

  const dataTo = tx.to ? tx.to : tx.created_contract;
  const columnNum =
    config.UI.views.tx.hiddenFields?.value &&
    config.UI.views.tx.hiddenFields?.tx_fee
      ? 2
      : 3;

  return (
    <Grid
      gridTemplateColumns={{
        lg:
          columnNum === 2
            ? '3fr minmax(auto, 180px) 170px'
            : '3fr minmax(auto, 180px) 170px 100px',
        xl:
          columnNum === 2
            ? '3fr minmax(auto, 250px) 170px'
            : '3fr minmax(auto, 275px) 170px 100px',
      }}
      position="relative"
      zIndex={2}
      gridGap={8}
      width="100%"
      minW="700px"
      border="1px solid"
      borderColor="divider"
      bgColor="black"
      mb={3}
      p={4}
      display={{ base: 'none', lg: 'grid' }}
      _before={renderTypeBorder()}
    >
      <Flex overflow="hidden" w="100%" position="relative">
        {/* <TxAdditionalInfo tx={tx} isLoading={isLoading} my="3px" /> */}

        {renderIcon()}

        <Box ml={3} w="calc(100% - 40px)">
          <HStack flexWrap="wrap" my="3px">
            {renderLabel()}

            <TxWatchListTags tx={tx} isLoading={isLoading} />
          </HStack>
          <Flex alignItems="center" mt="7px" mb="3px">
            <TxEntity isLoading={isLoading} hash={tx.hash} fontWeight="500" />
            <TimeAgoWithTooltip
              timestamp={tx.timestamp}
              enableIncrement
              isLoading={isLoading}
              color="text_secondary"
              fontWeight="400"
              fontSize="sm"
              flexShrink={0}
              ml={2}
            />
          </Flex>
        </Box>
      </Flex>

      <AddressFromTo
        from={tx.from}
        to={dataTo}
        isLoading={isLoading}
        mode="compact"
      />

      <Flex flexDir="column">
        {!config.UI.views.tx.hiddenFields?.value && (
          <Skeleton isLoaded={!isLoading} my="3px">
            <Text as="span" whiteSpace="pre">
              Value{' '}
            </Text>
            <Text as="span" color="text_secondary">
              {getValueWithUnit(tx.value).dp(5).toFormat()}{' '}
              {currencyUnits.ether}
            </Text>
          </Skeleton>
        )}
        {!config.UI.views.tx.hiddenFields?.tx_fee && (
          <Skeleton
            isLoaded={!isLoading}
            display="flex"
            whiteSpace="pre"
            my="3px"
          >
            <Text as="span">Fee </Text>
            <TxFee tx={tx} accuracy={5} color="text_secondary" />
          </Skeleton>
        )}
      </Flex>

      <div>
        <Box mt={5} />
        <TxStatus
          status={tx.status}
          errorText={tx.status === 'error' ? tx.result : undefined}
          isLoading={isLoading}
        />
      </div>
    </Grid>
  );
};

export default React.memo(LatestTxsItem);
