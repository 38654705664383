import type { ButtonProps } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import React from 'react';

interface GradientButtonProps extends ButtonProps {
  action: () => void;
  caption: string;
}

const GradientButton: React.FC<GradientButtonProps> = ({ action, caption, ...buttonProps }) => (
  <Button
    onClick={action}
    fontSize="sm"
    fontWeight="bold"
    color="white"
    bg="gray.900"
    px={6}
    py={3}
    borderRadius={0}
    textTransform="uppercase"
    border="1px solid transparent"
    css={{
      borderImageSource: 'linear-gradient(101.08deg, #EADFFF -101.01%, #A6C9EB -0.89%, #B8FCC3 101.33%)',
      borderImageSlice: 1,
    }}
    boxShadow="0px 4px 6px rgba(0, 0, 0, 0.1)"
    _hover={{
      transform: 'scale(1.03)',
    }}
    _active={{
      bg: 'gray.700',
    }}
    {...buttonProps}
  >
    {caption}
  </Button>
);

export default GradientButton;
